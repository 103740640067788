import React from 'react'
import Header from './components/header/Header'
import Content from './components/content/Content'
import Contact from './components/contact/Contact'
import Service from './components/service/Service'
import Design from './components/design/Design'
import Footer from './components/footer/Footer'
import About from './components/about/About'


const App = () => {
  return (
    <>
    
    <Header/>
    
    <Content />
    
    <Service/>
    <About />
    <Design/>
    <Contact/>
    <Footer />
    </>
    
  )
}

export default App
import React from 'react'
import './content.css'
const Content = () => {
  return (
    <div className='box' >
      <h2 className='cont'>Welcome to<strong>Balagiga's</strong></h2>
       <small><strong>We Make Our Mark</strong></small>
        
    </div>
  )
}

export default Content
import React from 'react'
import './design.css'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import image1 from '../assests/slide1.webp'
import image2 from '../assests/slide2.webp'
import image3 from '../assests/slide3.jpg'
import image4 from '../assests/slide4.jpeg'


const images = [
  image1,image2,image3,image4
];
const Design = () => {
  return (
    <div id='design'>
      <h1 className='content'>Invite us to your wedding, and we'll bring the sparkle with our<span> bright bangles</span> !!!</h1>

      <Slide>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[0]})` }}>
                    <span>"Eagerly Awaiting Your Big Day – Our Passion Is Making <br/>Your Wedding Dreams Come True!"</span>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[1]})` }}>
                    <span>"Around the Clock, Around the World – Our 24/7 Customer Support <br/> Ensures You're Never Alone in Your Journey to Brilliance!"</span>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[2]})` }}>
                    <span>"Standing Tall at Number 1 – Where Excellence Meets Elegance in <br/>Every Bangle We Offer!"</span>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[3]})` }}>
                    <span>"Constantly Striving for Perfection – Where Excellence Is Not Just <br/> a Standard, It's a Promise!"</span>
                </div>
            </div>
        </Slide>
    </div>
    
     
  
     

    
  )
}

export default Design
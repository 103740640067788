import React, { useState } from 'react';
import './about.css';

const WhatWeDo = () => {
  const [language, setLanguage] = useState('english');

  const toggleLanguage = () => {
    setLanguage(language === 'english' ? 'kannada' : 'english');
  };

  return (
    <div className="what-we-do">
      <div className="language-switch">
      <button className="small-button" onClick={toggleLanguage}>
          {language === 'english' ? 'ಕನ್ನಡ' : 'English'}
        </button>
      </div>
      <h2>{language === 'english' ? 'What We Do' : 'ನಾವು ಏನು ಮಾಡುತ್ತಿದ್ದೇವೆ'}</h2>
      <ul>
        <li>{language === 'english' ? 'After booking, we personally come to your marriage hall with our exquisite collection of bangles.' : 'ಬುಕಿಂಗ್ ಮಾಡಿದ ನಂತರ, ನಾವು ವೈಯಕ್ತಿಕವಾಗಿ ನಮ್ಮ ಸೊಗಸಾದ ಬಳೆಗಳ ಸಂಗ್ರಹದೊಂದಿಗೆ ನಿಮ್ಮ ಮದುವೆ ಮಂಟಪಕ್ಕೆ ಬರುತ್ತೇವೆ.'}</li>
        <li>{language === 'english' ? 'For the bride, we bring the elegance of Sani black bangles, carefully chosen for this special occasion.' : 'ವಧುವಿಗೆ, ನಾವು ಈ ವಿಶೇಷ ಸಂದರ್ಭಕ್ಕಾಗಿ ಎಚ್ಚರಿಕೆಯಿಂದ ಆಯ್ಕೆ ಮಾಡಿದ ಸಾನಿ ಕಪ್ಪು ಬಳೆಗಳ ಸೊಬಗನ್ನು ತರುತ್ತೇವೆ.'}</li>
        <li>{language === 'english' ? 'We extend our services to the relatives, offering bangles tailored to complement their attire and preferences.' : 'ನಾವು ನಮ್ಮ ಸೇವೆಗಳನ್ನು ಸಂಬಂಧಿಕರಿಗೆ ವಿಸ್ತರಿಸುತ್ತೇವೆ, ಅವರ ಉಡುಪು ಮತ್ತು ಆದ್ಯತೆಗಳಿಗೆ ಪೂರಕವಾಗಿ ಬಳೆಗಳನ್ನು ನೀಡುತ್ತೇವೆ.'}</li>
        <li>{language === 'english' ? 'We specialize in creating the perfect Baale Shatra, ensuring a delightful and culturally rich experience for all.' : 'ಪರಿಪೂರ್ಣ ಬಾಲೆ ಶಾಸ್ತ್ರವನ್ನು ರಚಿಸುವಲ್ಲಿ ನಾವು ಪರಿಣತಿ ಹೊಂದಿದ್ದೇವೆ, ಎಲ್ಲರಿಗೂ ಸಂತೋಷಕರ ಮತ್ತು ಸಾಂಸ್ಕೃತಿಕವಾಗಿ ಶ್ರೀಮಂತ ಅನುಭವವನ್ನು ಖಾತ್ರಿಪಡಿಸಿಕೊಳ್ಳುತ್ತೇವೆ.'}</li>
      </ul>
    </div>
  );
}

export default WhatWeDo;

import React from 'react';
import img2 from '../assests/fancy2.jpg';
import img3 from '../assests/groom.jpg';
import img6 from '../assests/w1.jpeg';
import img7 from '../assests/w2.jpeg';
import './service.css';

const contentArray = [
  {
    id: 1,
    title: 'Bangles for Bride and 100 women',
    image: img6,
    amount: '6000 /-',
  },
  {
    id: 2,
    title: 'Bangles for Bride and 50 women',
    image: img3,
    amount: '3000/-',
  },
  {
    id: 3,
    title: 'Bangles for Bride and women + Coloured Bangles',
    image: img2,
    content: 'Price depends on Selected unique bangles and number of Members',
    amount: '2000/-',
  },
  {
    id: 4,
    title: 'Bangles for Bride and 5 women',
    image: img7,
    amount: '1500/-',
  },
];

const Service = () => {
  const defaultMessage = encodeURIComponent('Hello, I need to book on Balagiga\'s.');
  const phoneNumber = '9845423581';

  return (
    <div className="content-list-container">
      {contentArray.map((item) => (
        <div key={item.id} className="content-item">
          <img className="img-des" src={item.image} alt={item.title} />
          <h2 className='clr'>{item.title}</h2>
          <p>{item.content}</p>
          <a
            href={`https://wa.me/${phoneNumber}?text=${defaultMessage}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn">Book @ <strong>{item.amount}</strong> </button>
          </a>
        </div>
      ))}
    </div>
  );
};

export default Service;

// Contact.js
import React from 'react';
import './contact.css';

const Contact = () => {
  const handleEmailClick = () => {
    const subject = encodeURIComponent('Contact Inquiry');
    const body = encodeURIComponent('Please enter your message here.');

    window.location.href = `mailto:banglestore007@gmail.com?subject=${subject}&body=${body}`;
  };

  const phoneNumber = '9845423581'; // Replace with your actual phone number

  return (
    <div className="contact-info">
      <h1 className="contact">Contact Me</h1>
      <p>Choose a contact option:</p>
      <button className="email-button" onClick={handleEmailClick}>
        Send Email
      </button>
      <p className="phone-info">Call me at: {phoneNumber}</p>
    </div>
  );
};

export default Contact;

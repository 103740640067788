import React from 'react'

import './header.css'

const Header = () => {
  return (
  <>
    <div id='main_page'>
   
    
    </div>
    
  </>
    
  )
}

export default Header